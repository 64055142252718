import {
    GET_REQUESTS,
    GET_DASHBOARD_DRAFT_REQUESTS
} from './actions'
import { combineReducers } from 'redux'
import createReducer from 'data4-commons/middlewares/api/createReducer'

const requests = createReducer(GET_REQUESTS)
const draftRequests = createReducer(GET_DASHBOARD_DRAFT_REQUESTS)

const dashboard = combineReducers({
    requests,
    draftRequests
})

export default dashboard
