import React, { Component, Fragment } from 'react'
// lib
import { SubmissionError } from 'redux-form'
// components
import { Container } from 'reactstrap'
// internal components
import Paper from '../../../components/Paper'
import LoginForm from './LoginForm'
import Activate2FAPage, { handleActivate } from '../2fa/Activate2FAPage'
import Check2FACodeForm from '../2fa/Check2FACodeForm'
import Activate2FASuccessPage from '../2fa/Activate2FASuccessPage'
import ChangePasswordPage, { handleLoninOnChangePassword } from '../password/expired/ChangePasswordPage'
import CheckPasswordStatusPage
    from '../password/expired/CheckPasswordStatusPage'
// api
import { login } from './api'
// utils
// const
import { AUTH_STEP } from './const'

const renderComponent = (step) => {
    switch (step) {
        case AUTH_STEP.ACTIVATE_2FA:
            return Activate2FAPage
        case AUTH_STEP.CHECK_2FA_CODE:
            return Check2FACodeForm
        case AUTH_STEP.SUCCESS_ACTIVATE_2FA:
            return Activate2FASuccessPage
        case AUTH_STEP.PWD_EXPIRED:
            return ChangePasswordPage
        case AUTH_STEP.CHECK_PWD_STATUS:
            return CheckPasswordStatusPage
        case AUTH_STEP.LOGIN:
        default:
            return LoginForm
    }
}

class LoginPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            step: AUTH_STEP.LOGIN
        }
    }

    handleError = (e) => {
        console.error(e)
        switch (e.status) {
            case 412: {
                return e.json()
                    .then((body) => {
                        return Promise.resolve(body.message)
                    })
            }
            case 500:
                return Promise.reject()
            default:
                try {
                    // 401, rejeté par fetchFactory en Error
                    const error = JSON.parse(e.message)
                    return Promise.reject(error.message)
                } catch (e) {
                    console.error(e)
                    return Promise.reject()
                }
        }
    }

    handleLogin = (values, dispatch, props) => {
        return login(values)
            .then((res) => {
                // vérifier l'état du mot de passe
                // enregistrer le lien de redirection
                props.change('redirection', res.redirection)
                return Promise.resolve(AUTH_STEP.CHECK_PWD_STATUS)
            }, (e) => {
                return this.handleError(e)
                    .catch((error) => {
                        if (typeof error === 'string') {
                            // Si le mdp est déjà expiré, on se redirige vers la changement
                            if (error === AUTH_STEP.PWD_EXPIRED) {
                                return Promise.resolve(error)
                            }
                            throw new SubmissionError({
                                _error: {
                                    id: `login.error.${error}`
                                }
                            })
                        }
                        throw new SubmissionError({
                            _error: {
                                id: 'global.unknown_error'
                            }
                        })
                    })
            })
    }

    getOnSubmit = (step) => {
        switch (step) {
            case AUTH_STEP.SUCCESS_ACTIVATE_2FA:
                return () => Promise.resolve(AUTH_STEP.CHECK_PWD_STATUS)
            case AUTH_STEP.PWD_EXPIRED:
                return handleLoninOnChangePassword(this.handleLogin)
            case AUTH_STEP.ACTIVATE_2FA:
                return handleActivate
            case AUTH_STEP.CHECK_PWD_STATUS:
                return () => Promise.resolve(AUTH_STEP.PWD_EXPIRED)
            default:
                return this.handleLogin
        }
    }

    onSubmitSuccess = (res) => {
        this.setState({
            step: res
        })
    }

    render() {
        const {
            step
        } = this.state
        return (
            <Fragment>
                <Container>
                    <Paper className="paper-form paper-login">
                        {
                            React.createElement(renderComponent(step), {
                                form: 'LoginForm',
                                destroyOnUnmount: false,
                                forceUnregisterOnUnmount: true,
                                onSubmit: this.getOnSubmit(step),
                                onSubmitSuccess: this.onSubmitSuccess,
                                handleError: this.handleError
                            })
                        }
                    </Paper>
                </Container>
            </Fragment>
        )
    }
}

export default LoginPage
