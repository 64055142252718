import middle from 'data4-commons/middlewares/api/middle'

const prefix = 'DASHBOARD/'

export const GET_REQUESTS = `${prefix}GET_REQUESTS`
export const GET_DASHBOARD_DRAFT_REQUESTS = `${prefix}GET_DRAFT_REQUESTS`

export const getDashboardRequests = (filters) => middle(GET_REQUESTS, {
    api: '/api',
    url: '/demandes/demandes',
    method: 'POST',
    options: {
        body: JSON.stringify(filters)
    }
})

export const getDashboardDraftRequests = (filters) => middle(GET_DASHBOARD_DRAFT_REQUESTS, {
    api: '/api',
    url: '/draft_requests',
    method: 'POST',
    options: {
        body: JSON.stringify(filters)
    }
})
