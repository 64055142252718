const LOGIN = 'LOGIN'
const ACTIVATE_2FA = 'ACTIVATE_2FA'
const CHECK_2FA_CODE = 'CHECK_2FA_CODE'
const PWD_EXPIRED = 'PWD_EXPIRED'
const SUCCESS_ACTIVATE_2FA = 'SUCCESS_ACTIVATE_2FA'
const CHECK_PWD_STATUS = 'CHECK_PWD_STATUS'

export const AUTH_STEP = {
    LOGIN,
    ACTIVATE_2FA,
    CHECK_2FA_CODE,
    PWD_EXPIRED,
    SUCCESS_ACTIVATE_2FA,
    CHECK_PWD_STATUS
}

export const AUTH_STEPS = [
    LOGIN,
    ACTIVATE_2FA,
    CHECK_2FA_CODE,
    PWD_EXPIRED,
    SUCCESS_ACTIVATE_2FA,
    CHECK_PWD_STATUS
]

const FETCH_QR_CODE = 'FETCH_QR_CODE'
const ACTIVATE = 'ACTIVATE'

export const ACTIVATION_2FA_ACTION = {
    FETCH_QR_CODE,
    ACTIVATE
}