import { DEMANDE_TYPE } from 'data4-commons/global'
import { canAccessRequest } from 'data4-commons/rights'
import { REQUEST_STATUS } from '../const'
import appConst from '../../../app/appConst'

export const SORT_VALUES = [
    appConst.requests.sort.open_date,
    appConst.requests.sort.date_intervention,
]

export const ALL_ESCALATION_VALUE = appConst.selector.all_value
export const ALL_TEAMS_VALUE = appConst.selector.all_value

const ALL_ESCALATION = {
    code: 0,
    value: ALL_ESCALATION_VALUE
}

const UNIQUE_ESCALATION = {
    code: 1,
    value: true
}

export const ESCALATION_OPTIONS = [
    ALL_ESCALATION,
    UNIQUE_ESCALATION
]

const PAGINATION_PARAMS = {
    sort: appConst.requests.sort.open_date,
    size: appConst.requests.pageSize
}

export const DEFAULT_REQUEST_STATUS_VALUES = [REQUEST_STATUS.OPEN, REQUEST_STATUS.IN_PROGRESS]

export const STATIC_FILTERS_INITIAL_VALUES = {
    ...PAGINATION_PARAMS,
    statuts: DEFAULT_REQUEST_STATUS_VALUES,
    escalade: ALL_ESCALATION_VALUE,
    equipe: ALL_TEAMS_VALUE
}

const FilterAccess = {
    type: DEMANDE_TYPE.ACCES,
    right: (user) => canAccessRequest(DEMANDE_TYPE.ACCES, user)
}

const FilterGeste = {
    type: DEMANDE_TYPE.GESTE_PROXIMITE,
    right: (user) => canAccessRequest(DEMANDE_TYPE.GESTE_PROXIMITE, user)
}

const FilterIncident = {
    type: DEMANDE_TYPE.INCIDENT,
    right: (user) => canAccessRequest(DEMANDE_TYPE.INCIDENT, user)
}

const FilterCfo = {
    type: DEMANDE_TYPE.CFO,
    right: (user) => canAccessRequest(DEMANDE_TYPE.CFO, user)
}

const FilterCfa = {
    type: DEMANDE_TYPE.CFA,
    right: (user) => canAccessRequest(DEMANDE_TYPE.CFA, user)
}

const FilterLivraison = {
    type: DEMANDE_TYPE.LIVRAISON,
    right: (user) => canAccessRequest(DEMANDE_TYPE.LIVRAISON, user)
}

const FilterRevocation = {
    type: DEMANDE_TYPE.REVOCATION,
    right: (user) => canAccessRequest(DEMANDE_TYPE.ACCES, user)
}

const FilterOther = {
    type: DEMANDE_TYPE.OTHER,
    right: (user) => canAccessRequest(DEMANDE_TYPE.OTHER, user)
}

export const FILTER_REQUEST_TYPES = [
    FilterAccess,
    FilterRevocation,
    FilterGeste,
    FilterIncident,
    FilterCfo,
    FilterCfa,
    FilterLivraison,
    FilterOther
]

export const REQUEST_FILTER_NAME = 'SideRequestFilters'

export const SEARCH_PARAM = 'search'
