import middle from 'data4-commons/middlewares/api/middle'
import { buildUrlWithParams } from 'data4-commons/entities/fetchEntities'
import { exportAccess as exportAccessApi, exportRevokedAccess as exportRevokedAccessApi } from './api'

const prefix = 'ACCESS/'

export const GET_LIST_ACCESS = `${prefix}GET_LIST_ACCESS`
export const EXPORT_ACCESS_START = `${prefix}EXPORT_ACCESS_START`
export const EXPORT_ACCESS_FINISH = `${prefix}EXPORT_ACCESS_FINISH`

export const getListAccess = (clientId, params) => middle(GET_LIST_ACCESS, {
    api: '/api',
    url: buildUrlWithParams(`/access/${clientId}`, params),
    method: 'GET'
})

export const exportAccess = (clientId, params) => (dispatch) => {
    dispatch({
        type: EXPORT_ACCESS_START
    })
    return exportAccessApi(clientId, params)
        .then((res) => {
            dispatch({
                type: EXPORT_ACCESS_FINISH
            })
            return res
        })
        .catch((e) => {
            dispatch({
                type: EXPORT_ACCESS_FINISH
            })
            throw e
        })
}

export const GET_LIST_ACCESS_REVOKE = `${prefix}GET_LIST_ACCESS_REVOKE`
export const EXPORT_ACCESS_REVOKE_START = `${prefix}EXPORT_ACCESS_REVOKE_START`
export const EXPORT_ACCESS_REVOKE_FINISH = `${prefix}EXPORT_ACCESS_REVOKE_FINISH`

export const getListAccessRevoke = (clientId, params) => middle(GET_LIST_ACCESS_REVOKE, {
    api: '/api',
    url: buildUrlWithParams(`/access/${clientId}/revoke`, params),
    method: 'GET'
})

export const exportRevokedAccess = (clientId, params) => (dispatch) => {
    dispatch({
        type: EXPORT_ACCESS_REVOKE_START
    })
    return exportRevokedAccessApi(clientId, params)
        .then((res) => {
            dispatch({
                type: EXPORT_ACCESS_REVOKE_FINISH
            })
            return res
        })
        .catch((e) => {
            dispatch({
                type: EXPORT_ACCESS_REVOKE_FINISH
            })
            throw e
        })
}
