import React from 'react'
import { Route, Switch } from 'react-router'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import LoginPage from './modules/login/LoginPage'
import WelcomePage from './modules/welcome/WelcomePage'
import ResetPasswordPage from './modules/password/reset/ResetPasswordPage'
import SupervisionPage from './modules/supervision/SupervisionPage'
import { appVersionSelector } from '../properties/selectors'
import Toaster from '../toaster/Toaster'
import { getTruncatedAppVersion } from 'data4-commons/utils'
import { FormattedMessage } from 'react-intl'
import { Button } from 'reactstrap'

const Root = ({
                  appVersion
              }) => {
    return (
        <div id="main" className="non-connected">
            <div className="full-bg">
                <div>
                    <span className="app-version">Data4 - {appVersion}</span>
                    <div className="display-login-page">
                        <div className="login-left">
                            <img className="logo" src="/resources/img/data4-logo.svg" alt="Logo Data4" />
                            <div className="d-desktop footer-left">
                                <FormattedMessage id="login.cities"></FormattedMessage>
                            </div>
                        </div>
                        <div className="login-right">
                            <div className="login-title-form" style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                margin: '10%'
                            }}>
                                <div className="headline login-big-title">
                                    <img src="/resources/img/pictoCWP2023.svg" alt="Picto Customer Portal" />
                                    <FormattedMessage id="login.customer_portal"></FormattedMessage>
                                </div>
                                <div className="margin-login">
                                <span className="headline login-md-title">
                                    <FormattedMessage id="login.welcome_title"></FormattedMessage>
                                </span>
                                    <Switch>
                                        <Route path="/supervision" component={SupervisionPage} />
                                        <Route path="/accueil/:siteId" component={WelcomePage} />
                                        <Route path="/reinitialiser/:email/:token" component={ResetPasswordPage} />
                                        <Route
                                            path="/set-new-password/:email/:token"
                                            render={(routeProps) => (
                                                <ResetPasswordPage
                                                    {...routeProps}
                                                    newPassword
                                                />
                                            )}
                                        />
                                        <Route component={LoginPage} />
                                    </Switch>
                                </div>
                            </div>
                            <div className="footer-right">
                                <a
                                    className="important"
                                    href="http://www.data4group.com"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >www.data4group.com</a>
                                <Button
                                    color="yellow"
                                    href="https://www.data4group.com/contact/"
                                    target="_blank"
                                ><FormattedMessage id="login.contact"></FormattedMessage></Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Toaster />
        </div>
    )
}

const mapStateToProps = createSelector([
    appVersionSelector
], (version) => ({
    appVersion: getTruncatedAppVersion(version)
}))

export default connect(mapStateToProps)(Root)
