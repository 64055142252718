import appConst from '../../../app/appConst'
import { DRAFT_REQUEST_STATUS } from '../const'

const PAGINATION_PARAMS = {
    sort: appConst.requests.sort.open_date,
    size: appConst.requests.pageSize
}

export const DEFAULT_REQUEST_STATUS_VALUES = [DRAFT_REQUEST_STATUS.TO_VALIDATE, DRAFT_REQUEST_STATUS.DENIED]

export const STATIC_FILTERS_INITIAL_VALUES = {
    ...PAGINATION_PARAMS,
    statuts: DEFAULT_REQUEST_STATUS_VALUES
}

export const DRAFT_REQUEST_FILTER_NAME = 'SideDraftRequestFilters'
